import React from "react"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"
import Home from "../components/UXUIDesignHome/Home"
const UiUxDesign = () => {
  return (
    <MainLayout>
      <Title>
        Codetrain Africa | Success Stories - Ghana | Apps coding bootcamp
      </Title>
      <Meta name="ux/uidesdign" content=" Codetrain Ui/Ux program" />
      <Home />
    </MainLayout>
  )
}

export default UiUxDesign

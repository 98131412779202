import React from "react"
import { Col, Row } from "react-bootstrap"
import "./videocard.css"

export default function VideoCard() {
  return (
    <Row className="video-card ">
      <Col md={2}></Col>
      <Col md={8}>
        <h3 className="text-center mt-4">
          Codetrain Experience Captured in video{" "}
        </h3>

        <Row>
          <Col
            md={6}
            className="d-flex justify-content-center flex-direction-column mt-4"
          >
            <Row className="flex-direct mx-3">
              <p>
                Embark on a thrilling journey with our summary video from the
                latest Demo Day event. Witness the excitement and innovation
                that unfolded!
              </p>

              <Row>
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/uqozP61jgKQ?si=rMOBcKv26IZGimnx"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Row>
            </Row>
          </Col>
          <Col md={6} className="d-flex justify-content-center mt-4">
            <Row className="flex-direct mx-3">
              <p>
                Here was an exciting Codetrain day out at Peduase!Codetrain
                Experience beyond coding — filled with fun, learning, and
                unforgettable moments.
              </p>

              <Row>
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/SmAZJcErw7w"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Row>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col md={2}></Col>
      <Row></Row>

      <Col lg={12} className="text-center ">
        <a
          href="https://tally.so/r/mVzBGj" // Replace with your actual href value
          className="btn-custom-primary-large enroll-section mx-auto enrol"
        >
          Enroll for UX/UI Design
        </a>
      </Col>
    </Row>
  )
}
